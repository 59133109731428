import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Accordion from '../../../../components/Accordion';
import MyHealthEmployeeHealthProgramContent from '../MyHealthEmployeeHealthProgramContent';
import ColonCancerScreeningImage from '../../../../assets/images/colonCancerScreening.png';
import HealthRiskImage from '../../../../assets/images/healthRiskCheck.png';

const ProductIcons = {
  colonCancer: ColonCancerScreeningImage,
  healthRiskCheck: HealthRiskImage
};

const MyHealthEmployeeTestsAccordion = ({
  toggle,
  handleInfo,
  handleModal,
  isOpen,
  tests
}) => {
  const intl = useIntl();
  const productCardsData = tests.map(test => {
    const productCardDescription = intl.formatMessage({
      id: `my-health.${test.type}.description`
    });
    return {
      title: <FormattedMessage id={`my-health.${test.type}.title`} />,
      price: null,
      description: productCardDescription,
      cta: {
        url: '#',
        text:
          test.quantity <= test.usedQuantity ? (
            <FormattedMessage id='my-health.ordered' defaultMessage='Ordered' />
          ) : (
            <FormattedMessage
              id='my-health.order-now'
              defaultMessage='Order now'
            />
          ),
        type: 'button',
        disabled: test.quantity <= test.usedQuantity
      },
      testTypeId: test.id,
      testType: test.type,
      iconUrl: ProductIcons[test.type]
    };
  });

  // sort the productCardsData alfabeticly by testType
  productCardsData.sort((a, b) => {
    return a.testType.localeCompare(b.testType);
  });

  return (
    <Accordion
      type={
        <FormattedMessage
          id='my-health.physical-health'
          defaultMessage='Physical health'
        />
      }
      title={
        <FormattedMessage
          id='my-health.health-check-from-home'
          defaultMessage='Health check with lab tests from home'
        />
      }
      isFirstAccordionOpen={isOpen}
      onToggle={toggle}
      content={
        <MyHealthEmployeeHealthProgramContent
          description={
            <FormattedMessage
              id='my-health.lab-test-from-home'
              defaultMessage='Lab test from home allow you to check for common health risks without having to go see a doctor. You take your sample from home, send it for analysis to Medisyn, one of Switzerlands largest laboratories, and receive your results and recommendations for actions online within 2-3 days.'
            />
          }
          productCards={productCardsData}
          handleInfo={handleInfo}
          handleModal={handleModal}
          footerCTA={{
            text: (
              <FormattedMessage
                id='my-health.lab-tests-how-it-works'
                defaultMessage='Lab test from home: How it works'
              />
            ),
            infoText: 'my-health.lab-tests-how-it-works-info'
          }}
        />
      }
    />
  );
};

MyHealthEmployeeTestsAccordion.propTypes = {
  toggle: PropTypes.func,
  handleInfo: PropTypes.func,
  handleModal: PropTypes.func,
  isOpen: PropTypes.bool,
  tests: PropTypes.array
};

export default MyHealthEmployeeTestsAccordion;
