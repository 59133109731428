import { useState, useEffect, useRef } from 'react';

export const ActiveTab = {
  summary: 'summary',
  doctor: 'doctor',
  lab: 'lab',
  stauffacher: "stauffacher",
  css: 'css',
  actions: 'actions'
};

const SECTION_POSITION_OFFSET = 200;

const handleLinks = data => {
  const { description, reports, labResults, partnerBlocks, recommendedActions } = data;
  let links = [];

  if (description && !reports.doctorReport) {
    links.push({
      textId: "app.summary",
      sectionsId: ActiveTab.summary
    });
  }
  if (reports.doctorReport) {
    links.push({
      textId: "app.doctors-report",
      sectionsId: ActiveTab.doctor
    });
  }
  if (labResults && labResults.length > 0) {
    links.push({
      textId: "test-result.lab-results",
      sectionsId: ActiveTab.lab
    });
  }
  if (partnerBlocks && partnerBlocks.stauffacherBlock) {
    links.push({
      textId: "test-result.partner-code.stauffacher-support",
      sectionsId: ActiveTab.stauffacher
    });
  }
  if (partnerBlocks && partnerBlocks.CSSBlock) {
    links.push({
      textId: "test-result.partner-code.css-support",
      sectionsId: ActiveTab.css
    });
  }
  if (recommendedActions) {
    links.push({
      textId: "app.recommended-actions",
      sectionsId: ActiveTab.actions
    });
  }

  return links;
};

const useScrollHandler = ({ data }) => {
  const linksData = handleLinks(data);
  const [activeSection, setActiveSection] = useState(linksData[0].sectionsId);

  const scrollableElementRef = useRef(null);

  const handleScroll = scrollTop => {
    const summaryScrollPosition = document.getElementById('summary')?.offsetTop;
    const doctorsReportPosition = document.getElementById('doctor')?.offsetTop;
    const labResultsPosition = document.getElementById('lab')?.offsetTop;
    const stauffacherPosition = document.getElementById('stauffacher')?.offsetTop;
    const cssPosition = document.getElementById('css')?.offsetTop;
    const recommendedActionsPosition = document.getElementById('actions')?.offsetTop;

    const positions = [
      { id: ActiveTab.summary, position: summaryScrollPosition },
      { id: ActiveTab.doctor, position: doctorsReportPosition },
      { id: ActiveTab.lab, position: labResultsPosition },
      { id: ActiveTab.stauffacher, position: stauffacherPosition },
      { id: ActiveTab.css, position: cssPosition },
      { id: ActiveTab.actions, position: recommendedActionsPosition }
    ].filter(pos => pos.position !== undefined);

    positions.sort((a, b) => a.position - b.position);

    for (let i = 0; i < positions.length; i++) {
      const { id, position } = positions[i];
      //TODO: for now this, later we change it so we dont use Infinity
      const nextPosition = positions[i + 1]?.position ?? Infinity;

      if (scrollTop >= position - SECTION_POSITION_OFFSET && scrollTop < nextPosition - SECTION_POSITION_OFFSET) {
        if (activeSection !== id) {
          setActiveSection(id);
        }
        break;
      }
    }
  };

  useEffect(() => {
    const scrollableElement = scrollableElementRef.current;

    const handleScrollEvent = () => {
      handleScroll(scrollableElement?.scrollTop, activeSection);
    };

    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', handleScrollEvent);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', handleScrollEvent);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection]);

  return {
    scrollableElementRef,
    activeSection,
    linksData
  };
};

export default useScrollHandler;
