import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Body2 } from '../../../../components/Typography';
import cx from 'classnames';

import './index.scss';

const HEADER_HEIGHT = 64;

const Link = ({ sectionsId, textId, isActive }) => {
  const linkRef = useRef(null);

  const handleOnClick = () => {
    const element = document.getElementById(sectionsId);
    if (element) {
      const parentElement = element.parentElement?.parentElement?.parentElement;
      if (parentElement) {
        const elementOffset = element.offsetTop;
        const parentOffset = parentElement.offsetTop;
        const scrollYPosition = elementOffset - parentOffset;

        parentElement.scrollTo({
          top: scrollYPosition - HEADER_HEIGHT,
          behavior: 'smooth'
        });
      }
    }

    adjustHorizontalScroll();
  };

  const adjustHorizontalScroll = () => {
    if (linkRef.current) {
      const scrollableParent = linkRef.current.parentElement;
      if (scrollableParent) {
        let leftSpacing = 24;
        const linkPosition = linkRef.current.offsetLeft;
        scrollableParent.scrollTo({
          left: linkPosition - leftSpacing,
          behavior: 'smooth'
        });
      }
    }
  };

  useEffect(() => {
    if (isActive) {
      adjustHorizontalScroll();
    }
  }, [isActive]);

  return (
    <button
      ref={linkRef}
      className={cx({
        link: true,
        'link--active': isActive
      })}
      onClick={handleOnClick}
    >
      <Body2 className='link__text'>
        <FormattedMessage id={textId} />
      </Body2>
    </button>
  );
};

export default Link;

Link.propTypes = {
  sectionsId: PropTypes.string,
  textId: PropTypes.node,
  isActive: PropTypes.bool
};
